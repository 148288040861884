jQuery(document).ready(function () {
	//sidr subtoggle
	jQuery('.sidr-class-parent:not(.sidr-class-active) ul').slideToggle().hide();
	jQuery('.sidr-class-parent').prepend("<span class='toggl0r'></span>");
	jQuery('.sidr-class-parent.sidr-class-active > span.toggl0r').addClass("open");
	jQuery('.sidr-class-parent > span.toggl0r').click(function () {
		jQuery(this).siblings('ul').slideToggle();
		jQuery(this).toggleClass("open");
		return false;
	});
	//sidr close on resize
	window.onresize = function () {
		jQuery.sidr('close', 'sidr-main');
	};
	jQuery(".nav_trigger").click(function () {
		jQuery(this).toggleClass("active").find(".innerTrigger").toggleClass("open");
		jQuery(this).parent().toggleClass("active");
	});
	// close alerts
	jQuery("a.close").click(function () {
		jQuery(this).parent().hide();
	});
	// responsive Dropdown
	jQuery(".nav.menu li:has(ul)").doubleTapToGo();
	// German Image Title
	if (jQuery("html").attr("lang") == "de-DE") {
		jQuery(".sigProContainer img").each(function () {
			jQuery(this).attr("title", "Zum Vergrößern klicken");
		});
	}

	// Slide fixed left/right
	jQuery(".content_fixed_right .moduletable>h3,.content_fixed_left .moduletable > h3").click(function () {

		if (jQuery(this).parent().hasClass("active")) {
			jQuery(this).parent().removeClass("active");
		} else {
			jQuery(".content_fixed_right .moduletable, .content_fixed_left .moduletable").removeClass("active");
			jQuery(this).parent().addClass("active");
		}
	});
	jQuery("button.scroll_to_top").click(function () {
		jQuery("html, body").animate({scrollTop: 0}, "slow");
		return false;
	});


	jQuery("select").niceSelect();
});
function toggleNewsletter(){
	console.log("TOGGLE NEWSLETTER")
	jQuery(".moduletable.newsletter").slideToggle();

}

function handleResizeScroll() {
	const $offset = jQuery(document).scrollTop();
	const $topTop = jQuery(".top_top");
	const $footer = jQuery("footer");

	if ($offset > 50) {
		$topTop.show();
	} else {
		$topTop.hide();
	}

	if ($footer.visible(true) === true) {
		$topTop.addClass("stick");
	} else {
		$topTop.removeClass("stick");
	}
}

window.addEventListener('resize', handleResizeScroll, { passive: true });
window.addEventListener('scroll', handleResizeScroll, { passive: true });

window.addEventListener('scroll', function(e) {
	const $scroll = jQuery(window).scrollTop();
	const $sketchwrapTop = jQuery(".sketchwrap_top");

	$scroll >= 67 ? $sketchwrapTop.addClass("solid").find(".topRow").slideUp() : $sketchwrapTop.removeClass("solid").find(".topRow").slideDown();
}, { passive: true });
// If alert, scroll in view at bottom of window
jQuery(window).on('load', function () {
	if (jQuery("#system-message").length > 0) {
		jQuery('html, body').animate({
			scrollTop: jQuery("#system-message").offset().top + jQuery("#system-message").outerHeight(true) - jQuery(window).height()
		}, 1000);
	}
});


